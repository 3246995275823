import { Button, Link } from "@mui/joy";
import { ReactNode } from "react";
import { AppConfig } from "../AppConfig";
import { Footer } from "../components/Footer";
import { Meta } from "../components/Meta";
import NavBar from "../components/navigation/NavBar";
import { Section } from "../components/Section";

import '../styles/global.css';

type IHeroOneButtonProps = {
    title: ReactNode;
    description: string;
    button: ReactNode;
  };


  const HeroOneButton = (props: IHeroOneButtonProps) => (
    <header className="text-center hero_text" style={{ color: 'white' }}>
      <h1 className="whitespace-pre-line text-3xl sm:text-5xl font-bold leading-tight sm:leading-hero">
        {props.title}
      </h1>
      <div className="mb-8 sm:mb-16 mt-2 sm:mt-4 text-lg sm:text-2xl px-4 sm:px-0">
        {props.description}
      </div>
      <div className="px-4 sm:px-0">
        {props.button}
      </div>
    </header>
  );
  

export default function Homepage() {
    return (
    <>
        <div className="text-gray-600 antialiased">
            <Meta title={AppConfig.title} description={AppConfig.description} />
            <NavBar />
            <Section yPadding="pt-10 pb-16 py-8 sm:pt-20 sm:pb-32 sm:py-16">
                <HeroOneButton
                  title={
                    <>
                        {'We help you build\nengagement with\n'}
                        <span className="text-primary-500">past contacts</span>
                    </>
                    }
                    description="The easiest way to re-engage with clients without all the noise."
                    button={
                    <Link href="/signup" className="mt-4 sm:mt-6">
                        <Button size="lg" color="primary" fullWidth>Sign Up Today</Button>
                    </Link>
                    }
                />
            </Section>
            <Section yPadding="pt-20 pb-32 py-16">
                <h1 className="text-4xl font-bold text-center mb-6">About Us</h1>
                <p className="text-center max-w-2xl mx-auto mb-8">
                    We are a team of developers who are passionate about helping businesses reconnect with their past contacts and build lasting relationships.
                </p>
                <p className="text-center max-w-2xl mx-auto">
                    Our platform provides an easy and efficient way to re-engage with clients, cutting through the noise and focusing on meaningful interactions.
                </p>
            </Section>
            {/* For the background image */}
            <div className="hero"></div>
            <Footer />
        </div>
    </>
    )
}

