import { GET, POST, PUT } from './requestClient';
import { ClientResponse } from '../interfaces';

export const GetAllCoachees = (filterValues: any = {}): Promise<ClientResponse<any>> => {
  const params = new URLSearchParams(filterValues).toString();
  return GET(`/api/coachees?${params}`);
};


// This is the Client-Side API Request:
export const getInactiveFellows = (months: number): Promise<ClientResponse<any>> => {
  return GET(`/api/inactive-fellows?months=${months}`);
};


export const GetCurrentCoach = (filterValues: any = {}): Promise<ClientResponse<any>> => {
  return GET(`/api/current_coach`);
};

export const UpdateCoach = (calendly_api_token: string): Promise<ClientResponse<any>> => {
  return PUT('/api/coach/update', { calendly_api_token: calendly_api_token });
}

export const UpdateCoachee = (id: number, first_name: String, last_name: String, email: String, phone: String): Promise<ClientResponse<any>> => {
  const body = {
    id: id,
    first_name: first_name,
    last_name: last_name,
    email: email,
    phone: phone
  }

  return PUT('/api/coachee/update', body);
}

export const MakeCall = (selectedCoachees: readonly number[], message: string): Promise<ClientResponse<any>> => {
  return POST('/api/voice-call', { coachee_ids: selectedCoachees, message: message });
}