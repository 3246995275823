import { Button } from "@mui/joy";
import { Link } from "react-router-dom";
import { AppConfig } from "../AppConfig";
import { Footer } from "../components/Footer";
import { Meta } from "../components/Meta";
import NavBar from "../components/navigation/NavBar";
import { Section } from "../components/Section";

export default function Privacy() {
    return (
    <>
        <div className="text-gray-600 antialiased">
            <Meta title={`${AppConfig.title} - Privacy Policy`} description="Privacy Policy for TouchBase Again" />
            <NavBar />
            <Section yPadding="py-8">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-3xl font-bold mb-6">TouchBase Again Privacy Policy</h1>
                    <div className="space-y-4">
                        <p>At TouchBase Again, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our SMS-based service.</p>

                        <h2 className="text-xl font-semibold">1. Information We Collect</h2>
                        <p>We collect the following types of information:</p>
                        <ul className="list-disc pl-6 space-y-2">
                            <li>Your mobile phone number</li>
                            <li>Contact information you provide</li>
                            <li>Interaction history with our service</li>
                        </ul>

                        <h2 className="text-xl font-semibold">2. How We Use Your Information</h2>
                        <p>We use your information to:</p>
                        <ul className="list-disc pl-6 space-y-2">
                            <li>Provide and improve our services</li>
                            <li>Send you reminders and suggestions</li>
                            <li>Analyze usage patterns to enhance user experience</li>
                        </ul>

                        <h2 className="text-xl font-semibold">3. SMS Communications</h2>
                        <p>By signing up for our service, you consent to receive SMS messages from us. You can opt-out at any time by texting "STOP" to our shortcode.</p>

                        <h2 className="text-xl font-semibold">4. Data Security</h2>
                        <p>We implement industry-standard security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

                        <h2 className="text-xl font-semibold">5. Third-Party Services</h2>
                        <p>We may use third-party services to help us operate our business. These services may have access to your personal information only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                        <h2 className="text-xl font-semibold">6. Changes to This Privacy Policy</h2>
                        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                        <h2 className="text-xl font-semibold">7. Contact Us</h2>
                        <p>If you have any questions about this Privacy Policy, please contact us at mikewash+touchbase@gmail.com.</p>
                    </div>
                    <div className="mt-8">
                        <Link to="/">
                            <Button variant="outlined" color="primary">Back to Home</Button>
                        </Link>
                    </div>
                </div>
            </Section>
            <Footer />
        </div>
    </>
    )
}
