import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import * as Pages from './pages';

export default function JoyOrderDashboardTemplate() {
  const token = localStorage.getItem('token');
  return (
    
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Pages.Homepage />} />
        <Route path="/dashboard" element={token ? <Pages.Dashboard /> : <Navigate to="/login" />} />
        <Route path="/login" element={<Pages.Login />} />
        <Route path="/signup" element={<Pages.SignUp />} />
        <Route path="/terms" element={<Pages.Terms />} />
        <Route path="/privacy" element={<Pages.Privacy />} />
      </Routes>
    </BrowserRouter>

  );
}
