/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { useEffect, useState } from 'react';
import { GetAllCoachees, UpdateCoachee, MakeCall, GetCurrentCoach, UpdateCoach } from '../api/serverClient';
import { Coach, Coachee } from '../interfaces';
import { Stack, Textarea, Tooltip } from '@mui/joy';
import { Info } from '@mui/icons-material';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function RowMenu() {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size='sm' sx={{ minWidth: 140 }}>
        <MenuItem>Edit</MenuItem>
        <MenuItem>Rename</MenuItem>
        <MenuItem>Move</MenuItem>
        <Divider />
        <MenuItem color='danger'>Delete</MenuItem>
      </Menu>
    </Dropdown>
  );
}

export default function OrderTable() {
  const [order, setOrder] = React.useState<Order>('desc');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [open, setOpen] = React.useState(false);
  const [coachees, setCoachees] = React.useState<Coachee[]>([]);
  const [groups, setGroups] = useState<string[]>([]); // Add state for groups
  const [filterValues, setFilterValues] = useState({ group: '', eventCount: '', search: '', inActiveMonths: '' }); // Add state for filter values
  const [modalInfo, setModalInfo] = useState({ id: 0, firstName: '', lastName: '', email: '', phone: '' });
  const [formOpen, setFormOpen] = React.useState(false);
  const [apiTokenModal, setApiTokenModal] = React.useState(false);
  const [apiToken, setApiToken] = useState('');
  const [showErrMsg, setShowErrMsg] = React.useState(false);
  const [coach, setCoach] = useState<Coach>();
  const [messageModal, setMessageModal] = React.useState(false);
  const [message, setMessage] = React.useState('');

  useEffect(() => {
    GetAllCoachees().then((res): void => {
      if (res.data) {
        setCoachees(res.data);
        const distinctGroups = [
          ...new Set<string>(
            res.data.map((coachee: Coachee) => coachee.group_name).filter((group: string | null): group is string => group !== null),
          ),
        ];
        setGroups(distinctGroups);
      }
    });
    GetCurrentCoach().then((res): void => {
      if (res.data) {
        setCoach(res.data);
      }
    });
  }, []);

  useEffect(() => {
    GetAllCoachees(filterValues).then((res): void => {
      if (res.data) {
        setCoachees(res.data);
      }
    });
  }, [filterValues]);

  const handleChange = (filterValue: any) => {
    setFilterValues({ ...filterValues, ...filterValue });
  };

  const inactiveFellowFilter = () => (
    <FormControl size='sm'>
    <FormLabel>Inactive Months</FormLabel>
    <Select
      size='sm'
      placeholder='All'
      onChange={(e: any) => {
        const selectedValue = e.target.dataset.value;
        handleChange({ inActiveMonths: selectedValue });
      }}
    >
      <Option value='' data-value={''}>
        All
      </Option>
      <Option value='1' data-value='1'>1 Month</Option>
      <Option value='2' data-value='2'>2 Months</Option>
      <Option value='3' data-value='3'>3 Months</Option>
    </Select>
    </FormControl>
    );

  const groupFilters = () => (
    <FormControl size='sm'>
      <FormLabel>Group</FormLabel>
      <Select
        size='sm'
        placeholder='All'
        defaultValue={filterValues.group}
        onChange={(e: any) => {
          handleChange({ group: e.target.dataset.value });
        }}
      >
        <Option value='' data-value={''}>
          All
        </Option>
        {groups.map((group) => (
          <Option key={group} value={group} data-value={group}>
            {group}
          </Option>
        ))}
      </Select>
    </FormControl>
  );
  
  const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    UpdateCoachee(modalInfo.id, modalInfo.firstName, modalInfo.lastName, modalInfo.email, modalInfo.phone).then((res): void => {
      if (res.data) {
        setCoachees((prevCoachees) =>
          prevCoachees.map((coachee) =>
            coachee.id === res.data.coachee.id ? { eventCount: coachee.eventCount, latestAttendedAtDate: coachee.latestAttendedAtDate, ...res.data.coachee } : coachee
          )
        );
      }
    });

    setFormOpen(false);
  };

  const handleApiTokenSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    UpdateCoach(apiToken).then((res): void => {
      if (res.data) {
        GetCurrentCoach().then((res): void => {
          if (res.data) {
            setCoach(res.data);
          }
        });
      }
    });

    setApiTokenModal(false);
  };

  const sendVoiceCall = async () => {
    if(selected.length === 0) {
      setShowErrMsg(true);
    } else {
      await MakeCall(selected, message);
    }
  };

  const renderFilters = () => <React.Fragment>{inactiveFellowFilter()}{groupFilters()}</React.Fragment>;
  return (
    <React.Fragment>
      <Sheet
        className='SearchAndFilters-mobile'
        sx={{
          display: { xs: 'flex', sm: 'none' },
          my: 1,
          gap: 1,
        }}
      >
        {/* //   <Input
      //   type={showPassword ? 'text' : 'password'}
      //   placeholder='Create Password'
      //   value={coach.password}
      //   name='password'
      //   onChange={(e) => {
      //     handleChange(e);
      //     validatePassword(e.target.value);
      //   }}
      // /> */}

        <Input
          type='text'
          size='sm'
          placeholder='Search???'
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton size='sm' variant='outlined' color='neutral' onClick={() => setOpen(true)}>
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby='filter-modal' layout='fullscreen'>
            <ModalClose />
            <Typography id='filter-modal' level='h2'>
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color='primary' onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
    {coach && !coach?.calendly_api_token && (   
      <Box sx={{ backgroundColor: 'blue', borderRadius: '16px', padding: '16px', width: '50%' }}>
        <Typography level="h2" sx={{ color: 'white' }}>Next Steps</Typography>
        <ol style={{ color: 'white' }}>
          <li>1. Go to this link and follow the instructions to set up your API token: <b><a href="https://developer.calendly.com/how-to-authenticate-with-personal-access-tokens" target="_blank" rel="noopener noreferrer">Calendly Authentication</a></b></li>
          <li>2. After you have your API token, <b><Button onClick={() => setApiTokenModal(true)} sx={{ backgroundColor: 'green' }}>Click Here</Button></b></li>
        </ol>
      </Box>
    )}
      <Box
        className='SearchAndFilters-tabletUp'
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size='sm'>
          <FormLabel>Search for users</FormLabel>
          <Input
            size='sm'
            placeholder='Search'
            startDecorator={<SearchIcon />}
            name='search'
            value={filterValues.search}
            onChange={(e) => handleChange({ search: e.target.value })}
          />
        </FormControl>
        {renderFilters()}
        <Modal open={showErrMsg} onClose={() => setShowErrMsg(false)}>
          <ModalDialog>
            <h2>Action Required:</h2>
            <p>Select Contacts</p>
            <button onClick={() => setShowErrMsg(false)}>OK</button>
          </ModalDialog>
        </Modal>
      </Box>
      { selected.length > 0 && (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button 
          color='success'
          onClick={() => setMessageModal(true)} 
          sx={{ marginBottom: '10px', flex: '1', maxWidth: '375px' }}
        >
          Send Automated Voice Call&nbsp; <Tooltip title="Ex: You can initiate an automated voice call to remind selected contacts about their monthly coach call"><Info /></Tooltip>
        </Button>
      </div>
      )}
        <Sheet
          className='OrderTableContainer'
        variant='outlined'
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby='tableTitle'
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                <Checkbox
                  size='sm'
                  indeterminate={selected.length > 0 && selected.length !== coachees.length}
                  checked={selected.length === coachees.length}
                  onChange={(event) => {
                    setSelected(event.target.checked ? coachees.map((coachee) => coachee.id) : []);
                  }}
                  color={
                    selected.length > 0 || selected.length === coachees.length
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>Name</th>
              <th style={{ width: 80, padding: '12px 6px' }}>Phone</th>
              <th style={{ width: 80, padding: '12px 6px' }}>Group</th>
              <th style={{ width: 100, padding: '12px 6px' }}>
                <Link
                  underline='none'
                  color='primary'
                  component='button'
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                  fontWeight='lg'
                  endDecorator={<ArrowDropDownIcon />}
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform: order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                  }}
                >
                  No. of Event Attended
                </Link>
              </th>
              <th style={{ width: 80, padding: '12px 6px' }}>Last Event Attended</th>
              <th style={{ width: 30, padding: '12px 6px' }}></th>
            </tr>
          </thead>
          <tbody>
            {stableSort(coachees, getComparator(order, 'eventCount')).map((row) => (
              // {coachees.map((row) => (
              <tr key={row.id}>
                <td style={{ textAlign: 'center', width: 120 }}>
                  <Checkbox
                    size='sm'
                    checked={selected.includes(row.id)}
                    color={selected.includes(row.id) ? 'primary' : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(row.id)
                          : ids.filter((itemId) => itemId !== row.id),
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Avatar size='sm'>
                      {row.first_name.charAt(0)}
                      {row.last_name.charAt(0)}
                    </Avatar>
                    <div>
                      <Typography level='body-xs'>
                        {row.first_name} {row.last_name}
                      </Typography>
                      <Typography level='body-xs'>{row.email}</Typography>
                    </div>
                  </Box>
                </td>
                <td>
                  <Typography level='body-xs'>{row.phone}</Typography>
                </td>
                <td>
                  <Typography level='body-xs'>{row.group_name}</Typography>
                </td>
                <td>
                  <Typography level='body-xs'>{row.eventCount}</Typography>
                </td>
                <td>
                  <Typography level='body-xs'>
                    {row.latestAttendedAtDate
                      ? new Date(row.latestAttendedAtDate)
                          .toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                          })
                          .replace(/\//g, '-')
                      : ''}
                  </Typography>
                </td>
                <td>
                  <Link
                    level='body-xs'
                    underline='hover'
                    color='primary'
                    component='button'
                    onClick={() => {setFormOpen(true); setModalInfo({id: row.id, firstName: row.first_name, lastName: row.last_name, email: row.email, phone: row.phone})}}
                  >
                    Edit
                  </Link>
                  
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>

      <Modal open={messageModal} onClose={() => setMessageModal(false)}>
        <ModalDialog>
          <Typography level="h4" component="h2" sx={{ mb: 2 }}>
            Enter your voice message in the box below and it will be used for the automated voice call
          </Typography>
          <form onSubmit={sendVoiceCall}>
            <FormControl sx={{ mb: 2 }}>
              <FormLabel>You can use the variable {`{first_name} or {last_name}`} to say each contact's first and/or last name in the voice message</FormLabel>
              <Textarea
                name="voice_message"
                placeholder="Put your automated voice message here"
                minRows={3}
                required
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormControl>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => setMessageModal(false)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="solid">
                Submit
              </Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>

      <Modal open={apiTokenModal} onClose={() => setApiTokenModal(false)}>
        <ModalDialog>
          <Typography level="h4" component="h2" sx={{ mb: 2 }}>
            Enter Your API Token
          </Typography>
          <form onSubmit={handleApiTokenSubmit}>
            <FormControl sx={{ mb: 2 }}>
              <FormLabel>Calendly API Token</FormLabel>
              <Textarea
                name="calendly_api_token"
                placeholder="Paste your API token here"
                minRows={3}
                required
                onChange={(e) => setApiToken(e.target.value)}
              />
            </FormControl>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => setApiTokenModal(false)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="solid">
                Submit
              </Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>

      <Modal open={formOpen} onClose={() => setFormOpen(false)}>
        <ModalDialog>
          <Typography level="h4" component="h2" sx={{ mb: 2 }}>
            Edit User Information
          </Typography>
          <form onSubmit={handleEditSubmit}>
            <Stack spacing={2} sx={{ minWidth: 300 }}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  name="firstname"
                  value={modalInfo.firstName}
                  onChange={(e) => {
                    setModalInfo((prevModalInfo) => ({
                      ...prevModalInfo,
                      firstName: e.target.value,
                    }));
                  }}
                  placeholder={modalInfo.firstName}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  name="lastname"
                  value={modalInfo.lastName}
                  onChange={(e) => {
                    setModalInfo((prevModalInfo) => ({
                      ...prevModalInfo,
                      lastName: e.target.value,
                    }));
                  }}
                  placeholder={modalInfo.lastName}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={modalInfo.email}
                  onChange={(e) => {
                    setModalInfo((prevModalInfo) => ({
                      ...prevModalInfo,
                      email: e.target.value,
                    }));
                  }}
                  placeholder={modalInfo.email}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  type="tel"
                  name="phone"
                  value={modalInfo.phone}
                  onChange={(e) => {
                    setModalInfo((prevModalInfo) => ({
                      ...prevModalInfo,
                      phone: e.target.value,
                    }));
                  }}
                  placeholder={modalInfo.phone}
                />
              </FormControl>
              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant="outlined"
                  color="neutral"
                  onClick={() => setFormOpen(false)}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="solid">
                  Update
                </Button>
              </Box>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
