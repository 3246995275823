import { Button } from "@mui/joy";
import { Link } from "react-router-dom";
import { AppConfig } from "../AppConfig";
import { Footer } from "../components/Footer";
import { Meta } from "../components/Meta";
import NavBar from "../components/navigation/NavBar";
import { Section } from "../components/Section";

export default function Terms() {
    return (
    <>
        <div className="text-gray-600 antialiased">
            <Meta title={AppConfig.title} description={AppConfig.description} />
            <NavBar />
            <Section yPadding="py-8">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-3xl font-bold mb-6">Touch Base Again Terms of Service</h1>
                    <div className="space-y-4">
                        <h2 className="text-xl font-semibold">Overview</h2>
                        <p>TouchBase Again is a service designed to help you maintain and strengthen your professional relationships. Our program offers the following key features:</p>
                        <ul className="list-disc pl-6 space-y-2">
                            <li>Automated reminders to reach out to your contacts</li>
                            <li>Personalized suggestions for conversation starters</li>
                            <li>Tracking of your interaction history</li>
                            <li>Insights into the strength of your relationships</li>
                            <li>Integration with your existing contact management systems</li>
                        </ul>
                        <p className="mt-4">By using TouchBase Again, you agree to use the service responsibly and in compliance with all applicable laws and regulations.</p>
                        <h2 className="text-xl font-semibold">1. Program Description</h2>
                        <p>TouchBase sends you periodic reminders and updates about your contacts. You can expect to receive messages prompting you to reach out to specific contacts, as well as suggestions for maintaining relationships.</p>

                        <h2 className="text-xl font-semibold">2. Cancellation</h2>
                        <p>You can cancel the SMS service at any time by texting "STOP" to our shortcode. We'll send a confirmation message upon unsubscription. To rejoin, simply sign up again as you did initially.</p>

                        <h2 className="text-xl font-semibold">3. Carrier Liability</h2>
                        <p>Carriers are not liable for delayed or undelivered messages.</p>

                        <h2 className="text-xl font-semibold">4. Message and Data Rates</h2>
                        <p>Message and data rates may apply for messages sent between us. You will receive up to 5 messages per week. For questions about your text or data plan, please contact your wireless provider.</p>
                        
                        <h2 className="text-xl font-semibold">5. Consent</h2>
                        <p>By providing your mobile number, you expressly consent to receive text messages from MP Washington LLC related to TouchBase.</p>

                        <h2 className="text-xl font-semibold">6. Supported Carriers</h2>
                        <p>Supported carriers include AT&T, Verizon, T-Mobile, Sprint, U.S. Cellular, and most other major carriers in the United States. Carrier message and data rates may apply.</p>

                        <h2 className="text-xl font-semibold">7. Privacy Policy</h2>
                        <p>Your privacy is important to us. Please review our <Link to="/privacy" style={{ color: 'blue', textDecoration: 'underline' }}>Privacy Policy</Link>.</p>

                        <h2 className="text-xl font-semibold">8. Terms Changes</h2>
                        <p>MP Washington LLC reserves the right to change these Terms of Service at any time. We will notify you of any changes by posting the new Terms of Service on this page.</p>

                        <h2 className="text-xl font-semibold">9. Contact Us</h2>
                        <p>If you have any questions about these Terms of Service, please contact us at mikewash+touchbase@gmail.com.</p>
                    </div>
                    <div className="mt-8">
                        <Link to="/">
                            <Button variant="outlined" color="primary">Back to Home</Button>
                        </Link>
                    </div>
                </div>
            </Section>

        {/* <Footer /> */}
        <Footer />
        </div>
    </>
    )
}