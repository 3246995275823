import { Link, Typography } from '@mui/joy';
import type { ReactNode } from 'react';
import { Background } from './BackGround';
import { Section } from './Section';


type ICenteredFooterProps = {
  logo: ReactNode;
  iconList: ReactNode;
  children: ReactNode;
};


const CenteredFooter = (props: ICenteredFooterProps) => (
  <div className="text-center">
    {props.logo}

    <nav>
      <ul className="navbar mt-5 flex flex-row justify-center text-xl font-medium text-gray-800">
        {props.children}
      </ul>
    </nav>
  </div>
);

const Footer = () => (
  <Background color="bg-gray-100">
    <Section>
      <CenteredFooter
        logo={<div></div>}
        iconList={<div>Your Icon List Here</div>}
      >
        <li>
          <Link href="/">Home</Link>
        </li>
        <li>
          <Link href="/privacy">Privacy</Link>
        </li>
        <li>
          <Link href="/terms">Terms</Link>
        </li>
      </CenteredFooter>
      <CenteredFooter
        logo={<div></div>}
        iconList={<div>Your Icon List Here</div>}
      >
        <div className="flex flex-col">
          <Typography level="body-sm" sx={{ fontWeight: 100 }}>Copyright © 2024 MP Washington LLC, All rights reserved.</Typography>
          <br />
          <Typography level="body-sm" sx={{ fontWeight: 100 }}>2803 Philadelphia Pike, Suite B #1121, Claymont, DE 19703</Typography>
        </div>
      </CenteredFooter>
    </Section>
  </Background>
);

export { Footer };
