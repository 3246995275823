import type { ReactNode } from 'react';
import { Link } from '@mui/joy';

type INavbarProps = {
  logo?: ReactNode | null;
  children: ReactNode;
};

const NavbarTwoColumns = (props: INavbarProps) => (
  <div className="flex flex-wrap items-center justify-between">

    {props.logo && (
        <div>
        <Link href="/">{props.logo}</Link>
        </div>
    )}

    <nav>
      <ul className="navbar flex items-center text-xl font-medium text-gray-800">
        {props.children}
      </ul>
    </nav>
  </div>
);

export { NavbarTwoColumns };