import { Link } from '@mui/joy';
import MuiLogo from "../MuiLogo";
import { NavbarTwoColumns } from "./NavbarTwoColumns";
import { Section } from "../Section";
import { Background } from "../BackGround";

const NavBar = () => {
  return (
    <Background color="bg-gray-100">
      <Section yPadding="py-6">
        <NavbarTwoColumns logo={<MuiLogo />}>
          <li className="navbar">
            <Link href="/login">Log in</Link>
          </li>
          <li className="navbar">
            <Link href="/terms">Terms</Link>
          </li>
        </NavbarTwoColumns>
      </Section>
    </Background>
  );
};

export default NavBar;
