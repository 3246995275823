import React, { useState } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { POST } from '../api/requestClient';
import { Link } from '@mui/joy';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function handleLogin(e: any) {
    e.preventDefault();
    try {
      
      const response = await POST('/api/login', { email, password });
      
      if (response.data.error) {
        alert(response.data.error);
      } else {
        localStorage.setItem('token', response.data.token);
        window.location.href = '/dashboard';
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  }

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box
        component='main'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          gap: 2,
          p: 2,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '300px', border: '2px solid #D3D3D3', borderRadius: '8px', px: 2, py: 3, boxShadow: 'md'}}>
          <Typography level='h3' component='h1' sx={{ textAlign: 'center' }}>
            Welcome!
          </Typography>
          <Typography  sx={{ textAlign: 'center', mb: 2 }}>
            Sign in to continue.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl id='email' required size='sm' color='primary'>
              <FormLabel sx={{ marginBottom: 1, fontSize: '18px' }}>Email</FormLabel>
              <Input
                type='email'
                placeholder='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
         
            <FormControl id='password' required size='sm' color='primary'>
              <FormLabel sx={{ marginBottom: 1, fontSize: '18px' }}>Password</FormLabel>
              <Input
                type='password'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center',  paddingTop: '20px', mt: 2 }}>
            <Button 
              type='submit' 
              sx={{ 

                width: '100%', // This makes the button take up the full width
              }} 
              onClick={(e) => handleLogin(e)}
            >
              &nbsp;&nbsp;Log in&nbsp;&nbsp;
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center',  paddingTop: '20px' }}>
                        Don't have an account?{' '}&nbsp;&nbsp;
                        <Link href='/signup' sx={{ ml: 1 }}>Sign up</Link>
                        </Box>
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
